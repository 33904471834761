/* Position and sizing of burger button */
.bm-burger-button {
  /* position: fixed; */
  position:relative;
  display: flex;
  width: 1.2em;
  height: 1.2em;
  max-width: 40px;
  max-height: 40px;
  /* right: 36px;
  top: 36px; */
  /* display: initial; */
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #3d479e;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px;
  width: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  /* height: 100%; */
  /* max-height: 90vh; */
  /* bottom: 0px; */
  top:0px;
  width: 200px !important;
  height: unset !important;
}

/* General sidebar styles */
.bm-menu {
  background: #323a4b;
  padding: 0;
  font-size: inherit;
  height: inherit;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #002fff;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  padding-top: 1.8em;
  border-color: white;
  border-style: solid;
  height: calc(100vh - 2.9em) !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-top: .6em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(255, 0, 0, 0.884);
  display: none;
}
