
.scrollTop {
  position: fixed;
  right: 1.2em;
  bottom: 1.2em;
  width: 1.7em;
  height: 1.5em;
  /* opacity: 1; */
  /* background-color: rgb(255, 255, 255); */
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.3em;
}
.scrollTop:hover{
  background-color: rgba(0, 0, 0, 0.8);
}
.scrollTop:before, .scrollTop:after{
  position: fixed;
  /* right:0.2em; */
  /* left: 0em; */
  content: ' ';
  height: 0.07em;
  width: .7em;
  background-color: white;
}
.scrollTop:before {
  transform: 
  translateX(0.85em)
  translateY(.45em)  
  rotate(45deg) 
  ;
  transform-origin: left;
}

.scrollTop:after {
  transform: 
  translateX(0.15em)
  translateY(.450em)
  rotate(-45deg) 
  ;
  transform-origin: right;
}


.close {
  position: absolute;
  right: 40px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
}
.nonDrag{
  --user-drag: none; 
  --user-select: none;
  --moz-user-select: none;
  --webkit-user-drag: none;
  --webkit-user-select: none;
  --ms-user-select: none;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: rgb(255, 255, 255);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.arrow{
  width: 1.5em;
  height: 2em;
  /* opacity: 1; */
  /* background-color: rgb(255, 255, 255); */
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.3em;
}
.arrow:hover{
  background-color: rgba(0, 0, 0, 0.8);
}
.arrow:before, .arrow:after{
  position: fixed;
  /* right:0.2em; */
  content: ' ';
  height: 0.07em;
  width: 1em;
  background-color: white;
}
.rightArrow:before {
  transform: 
  translateX(0.15em)
  translateY(1em)  
  rotate(45deg) 
  ;
  transform-origin: right;
}

.rightArrow:after {
  transform: 
  translateX(0.15em)
  translateY(1em)
  rotate(-45deg) 
  ;
  transform-origin: right;
}

.leftArrow:before{
  transform: 
  translateX(0.30em)
  translateY(1em)  
  rotate(45deg) 
  ;
  transform-origin: left;
}

.leftArrow:after {
  transform: 
  translateX(0.30em)
  translateY(1em)
  rotate(-45deg) 
  ;
  transform-origin: left;
}