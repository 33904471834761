:root{
  --debug-show-layout-articles-summary: none;
  /* --debug-show-layout-articles-summary: solid; */
}

.articlesSummary{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-color: white;
  border-style: var(--debug-show-layout-articles-summary);

}

.articlesSummary > h2{
  /* color: green; */
  padding-left: 7vh;
}

.alItem{
  /* color: red; */
  border-color: red;
  border-style: var(--debug-show-layout-articles-summary);

  margin-bottom: 3vh;
  /* width: 100%; */
}

.alTitle{
  font-size: larger;
  font-weight: bold;
}

.alAbstract {
  font-size: larger;
  /* text-decoration: dashed; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          /* line-clamp: 2;  */
  -webkit-box-orient: vertical;
}
.hiddenLink{
  position:absolute; 
  width:100%;
  height:100%;
  top:0;
  left: 0;

  z-index: 1;

  /* fixes overlap error in IE7/8, 
      make sure you have an empty gif */
  /* background-image: url('empty.gif'); */
}

