/* .toc-content{
  height:auto;
}

.toc-content:not(.show){
  transform:translateY(-100em);
  // display:none; 
  transition:ease-out 1s;
  --moz-animation: ease-out 1s;
  overflow: clip;
} */

.toc-content {
  overflow:hidden;
  transition:max-height 0.3s ease-out; /*  note that we're transitioning max-height, not height!*/
  height:auto;
  max-height:none;
}
.toc-content.collapsed {
  max-height:0;
}