.bibli{
  display: flex;
  flex-direction: column;
}
.bib-entry{
  display:flex;
  flex-direction: row;
}
.bib-item {
  /* display: table-cell; */
  /* margin-bottom: .5em; */
  padding-bottom: .5em;
  /* margin-left: 2em; */
  overflow-wrap: anywhere;
}
.bib-bullet{
  /* display: table-cell; */
  /* content: "[" counter(item) "]"; */
  /* counter-increment: item; */
  min-width: 2.5em;
  /* margin-left: -2em; */
}

/* .katex-display{
  overflow: auto hidden;
  
} */


/* .katex-display > .katex { white-space: normal } */
/* Add space between broken lines: */
/* .katex-display > .base { margin: 0.25em 0 } */
/* Compensate by reducing space around display math */
/* .katex-display { margin: 0.5em 0; } */