:root{
  --debug-show-layout: none;
  /* --debug-show-layout: solid; */
}

/*TODO :
  - there is a jump when the screen width changes: the height of the navbar jumps when links disappear
*/

.App {
/* html { */
  text-align: left;
  /* margin-left: 2vh; */
  display:flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;


}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  /* min-height: 10vh; */
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  font-size: calc(10px + 2vmin);
  color: white;
  /* background-color: red; */
  /* margin-left: 5vh; */
}

.App-link {
  color: #61dafb;
}

.link-nostyle{
  color: white;
  text-decoration: none;
}

.link-ul{
  color: inherit;
  text-decoration: none;
  text-decoration-line: underline;
}

.link-header-uline{
  color: white;
  text-decoration: none;
  /* text-decoration-line: underline !important; */
  border-bottom: .2em solid white;
}

.headerNavbar{
  border-color: white;
  border-style: solid;
  display: flex;
  align-items: center;
}



.navbar > * {
  /* color: red; */
  border-color: yellow;
  border-style: var(--debug-show-layout);
  display: flex;
  align-items: stretch;
  /* border-radius: 10px; */
}

.navbar-brand{
  /* color: red; */
  font-weight: bolder;
  font-size: larger;
}

.navbar > *>*{
  /* color: green; */
  /* border-color: blue;
  border-style: solid; */
  align-self:center;
  padding-left: 7vh;
}

.nav-link{
  /* color: red; */
  text-decoration: none;
  margin-left: 7vh;
}

.navbar-nav{
  /* background-color: red; */
  padding-left: 0vh;
}

.App-body {
  background-color: #282c34;
  /* background-color: red; */
  /* min-height: 100vh; */
  flex:1;
  display: flex;
  flex-direction:column;
  align-items: center;
  align-self:stretch;

  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  border-color: rgb(192, 59, 59);
  border-style: var(--debug-show-layout);
  /* background-color: red; */
  /* margin-left: 5vh; */
}

.body-content{
  display:flex;
  /* flex-direction: row; */
  flex:1;
  align-self: center;
  min-width: 80vw;
  border-color: green;
  border-style: var(--debug-show-layout);
  /* min-height: 80vh; */
}

.main-content{
  
  border-color: blue;
  border-style: var(--debug-show-layout);
  text-align: left;
  padding-left: 10vw;
  padding-right: 10vw;
  /* min-height: max-content; */
  /* flex-grow: 1; */
  display:flex;
  flex-direction: row;

  flex:1;
}

.single-line-content{
  /* color:red; */
  border-color: red;
  border-style: var(--debug-show-layout);
  /* min-height:; */
  text-align: center;
  vertical-align: center;
  /* display: flex; */
  flex:1;
  align-self: center;
}

.burger-menu{
  display: initial;
  border-color: red;
  border-style: var(--debug-show-layout);
  display:flex;
  flex-direction: row;
  flex:1;
  align-self:stretch;
}

.burger-menu > .spacer{
  flex:1;
}

.burger-menu > .menu{
  padding: 10px;
  align-self: stretch;
  display: flex;
  align-items: center;
  border-color: green;
  border-style: var(--debug-show-layout);
}

/* Mobile view*/
@media (max-width: 648px) {
  .mr-auto{
    /* color: red; */
    display: none;
  }

  
}

img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
